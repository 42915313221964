#ForecastContent{
    overflow-x: scroll;
    
}

.SectionTitleToolbar{
    
    font-size: medium;
    padding-left: 25px;
    padding-right: 25px;
    color: black;
    padding-top: 1vh;
    padding-bottom: 1vh ;

}
.SectionTitleIcon{
    font-size: x-large;
    margin-right: 2%;
    
}
#date{
    text-transform: uppercase;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

    
}
#copyrightCard{
    box-shadow: none;
    margin: 0;
    background-color:#094183;
    color: white;
    border-radius: 0;

}
#copyright{
    box-shadow: none;
    font-size: 12px;

}
#disclaimerCard{
    text-align: center;
    box-shadow: none;
}


.signInPop .popover-content{
    width: 95vw !important;
    left: 2.5vw !important;
 
}
#weatherPage{
    --padding-top: 15px;
   
}

@media(min-width:700px){

    .SectionTitleToolbar{
        padding-top: 2vh;
        padding-bottom: 2vh ;
        font-size: x-large;
        
    }
    .SectionTitleIcon{
        font-size: x-large;
    }

    .signInPop .popover-content{
        width: 70vw !important;
        left:  15vw !important;
     
    }

    .signInPop{
        text-align: center;
    }

}


