

.cooperatorImg{
    margin-top:30px;
    margin-bottom: 20px;
    width:75%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    
}
.settingText{
    padding: 30px; 
    border-bottom:2px solid grey;
    margin-bottom: 40px;
    line-height: 1.5;
    padding-top: 0px 
}

@media (min-width:700px){
    .cooperatorImg{
        width:50%;
        display: block;
    }
    .settingText{
        font-size: large;
    }
}

@media (min-width:1000px){
    
    .settingText{

        font-size: x-large;
    }
}