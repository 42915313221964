.districtText{
    color: #333333;

}
.airQualitySite{
    color: gray;
}
.airQualityLevel{
    font-size: 10vw;
    
}
#weatherTitle{
    margin-left: 5px;
}

@media(min-width:700px){

    .districtText{
        font-size: x-large;
        
    }
    .airQualitySite{
        font-size: 28px;
        
    }
    .airQualityLevel{
        font-size: xxx-large;

    }

}