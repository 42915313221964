#surveysContent{
    --ion-background-color:'#f9f9f9'
}

#profileInformationCard{
    padding:10px;
    margin: 10px;
    height: auto;
    box-shadow: none;
    background-color: white;
}
#surveyRow{
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap;

}
#userID{
    font-weight: bold;
    font-size: 20px;
    
}
#profile{
    position: absolute;
    left: 0;
    display: o;
}

.surveyImg{
    text-align: center;
    width:70px;
    padding-top: 20px;
    margin-left:30px ;
    
}



.surveyCard{
    height: 90px;
    width: 250px;
    display: inline-block;
    padding-top: 0;
    border-radius: 20px;
    
    
}
    

#surveyName{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: bold;
    margin: auto;
}
#surveyIcon{
    padding-top: 7px;
    font-size: 40px;
    display: inline-block;
    color:#3880ff ;
}

.surveyDescrptionTitle{
   padding-top: 10px;
    font-size: medium;
    padding-bottom: 0;
}

.surveyDescription{
   padding:0px;
   font-size:14px;
   text-align: justify;
}



.surveyDetailCard{
    box-shadow: none;
    text-align: center;
    padding-top:10px;
    margin: 0;

    
}

#plusImg{
    margin-right: 20px;
    height:30px;
    width: 30px;
}
#takeSurvey{
    background-color: #4587C3;
    color:white ;
    padding-left: 10px;

}
#surveySignIn{
    color:blue
}
#surveySignInText{
    margin-top: 50px;
    font-size: medium;
}

#surveyDescriptionSubtitle{
    padding-top: 10px;
    font-size:medium;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding-right: 10px;

}


.surveyModal{
    --width:100vw;
    --height:100vh
}

.fullScreenPop .popover-content{
    width: 100vw;
    height:100vh;
   
    position: static;

}
#surveyCard{
    box-shadow: none;
    padding-top: 10px;
    background-color:#f9f9f9; 
}

@media(min-width:700px){

    .surveyCard{
        width: 250px;

    }

    .anotherSurveyCard{
        width: 20vw;
  
    }

}
   

