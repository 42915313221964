
#countGrid{
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
}

/* For location chooser*/
#locationRow{
    margin-top: -3vh;
    padding-top: 0;
    height: 100px;
}
#locationCard{
    box-shadow: none;
    margin-top: 0;
}
#locationHint{
    font-size: medium;
    color: black;
    font-family:  'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
#placeHolder{
    border-bottom-color:#ECECEC;
    border-bottom-width: 2px;
    border-bottom-style: ridge;
    
    font-weight: bold;
    
}

/* For the row shows the forecast and the weather of today*/
.forecastRow{
    border-bottom-color:#ECECEC;
    border-bottom-width: 2px;
    border-bottom-style: solid;

    margin-bottom: 0;
    margin-left: 4%;
    margin-right: 4%;
    margin-top: 2%;
}

/* For the card shows the forecast pollen level of today*/
#forecastCard{
    box-shadow: none;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    text-align: center;

}

#forecastIntroText{
    text-align: center;
    padding-top: 0;
   
    font-size: medium;
    line-height: 18px;
    padding-bottom: 0;
}

#forecastLevel{
    text-align: center;
    
    font-size: xx-large;
    padding-bottom: 0;
    padding-top: 1px;
}


/* For the card shows the count pollen level of yesterday*/
#otherInfoCard{
    box-shadow: none;
    text-align: center;
    padding-top: 25%;
    margin: 0;
}
.infoIntro{
    padding-bottom: 0;
    padding-top: 0;
    
}
#lastLevel{
    font-size: large;
    padding-top: 0;
    
    padding-bottom: 1;
}
#site{
    color: #767676;
    font-size: large;
    padding-top: 0;
}

/* For the card shows the count weather of today*/
.weatherTodayCard{
    box-shadow: none;
    margin-bottom: 0;
    padding-top: 1%;
    margin-top: 0;

    border-bottom-color:#ECECEC;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-radius: 0;
    width: 95%;
}

.weatherIntroCol{
    flex: 0 0 10px;
}

.weatherInfoTitle{
    font-size: medium;
    line-height: 20px;
    width: 60vw;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}
.infoIcon{
    padding-right: 3%;
}
.detailText{
    position: absolute;
    right: 0;
    padding-top: 1px;
    font-size: medium;
    
}
#Thunderstorm{
    border-bottom-style: none
}

.weatherInfo{
    width: 75vw;
    font-size: large;
    line-height: 15px;
    font-family: IBM Plex Sans;
    font-weight: 400;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 2%;
}


.turnPageIcon{
    position: absolute;
    right: 0;
    padding-top: 1px;
    font-size: medium;
}


@media(max-width:400px){
    .weatherInfoTitle{
        font-size: small;
    }
    .detailText{
        font-size: small;
        
    }

    #todayLevelChart{
        width : 60px!important;
        max-height: 80px;
    }
}
@media(min-width:550px){
    #locationRow{
        margin-top: -2vh;
    }
 
    #forecastCard{
        padding-top: 2vh;


    }
    #forecastIntroText{
        font-size: large;
        line-height: 30px;
    }


    #forecastLevel{
        text-align: center;
        
        font-size: xx-large;
        padding-bottom: 0;
        padding-top: 1px;
    }
    
    #otherInfoCard{
        font-size: large;
        padding-top: 10%;
    }
    .infoIntro{
        font-size: large;
    }
    #lastLevel{
        font-size: x-large;
    }
    #site{
        font-size: x-large;
    }
    #locationHint{
        font-size: 20px;
    }
    .weatherInfoTitle{
        
        padding-top: 1.5vh;
        font-size: 22px;
        width: 30vw;
        height: 3vh;
        line-height: 3vh;
        margin-bottom: 7vh;
        text-align: justify;
        
    }
   
    .weatherInfo{
        font-size: 20px;
        line-height: 30px;
        padding-top: 1px;
        margin: 0 auto;
        padding-bottom: 3vh;
        width: 22vw;
        
    }
    .infoIcon{
        text-align: left;
        font-size: 50px;
        color:#5386c4;
        width: 7vw;
    }



    .weatherTodayCard{
        
        margin: 0;
        width: 30vw;
        height: auto;
        text-align: center;
       
        
        font-size: xx-large;
        
        border-radius: 0;
    }
    .detailText{
        position: relative;
        font-size: 25px;
        

    }

    .turnPageIcon{
        color:white
    }
    #weatherCard{
        border-right-color:#ECECEC;
        border-right-style: solid;
        border-right-width: 2px;
        border-bottom: none;
    }
    .forecastRow{
        border-bottom-width: 4px ;
        
    }
    #todayLevelChart{
        position: relative;
        left: 0;
    }

}
@media(min-height:900px){
    .forecastRow{
        padding-top: 20px;        
    }
}
@media(min-width:900px){

    .infoIcon{
        font-size: 45px;
        width: 5vw;
    }
    .weatherInfoTitle{
       
        font-size: 22px;
        width: 23vw;
        height: 3vh;
        line-height: 3vh;
        margin-bottom: 5vh;
        text-align: left;

        
    }


    .weatherTodayCard{

        width: 21.5vw;
        height: auto;

    }
    .weatherInfo{
        padding-top: 10px;
        font-size: x-large;
    }



}