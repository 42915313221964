
.RegisterCard{
    box-shadow: none;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    
}

.registerInputField{
    text-align: left;
}
.my-custom-class{
    text-align: center;

}
#successfulCard{
    box-shadow: none;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    
}
#successfulReturnButton{
    margin-top:10px;
    
}
.registerInput{
    border-bottom: 1px solid;
 
    
    margin-top: 5px;
    margin-bottom: 10px;
}
@media(min-width:600px){
    #successfulCard{
        width: 50vw;
        
    }
    #successfulPopover .popover-content{
        width: 50vw;
        position: static;
        
    }

    
}