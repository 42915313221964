
.iconText{
  position: relative;
  top: 20%;

  color:#737373;
}
.questionCard{
 
    margin: 0;
    padding:0;
    
    
}
.questionAnswer{
   
    text-align: left;
    background-color:#f9f9f9 ;
    border-radius: 10px;
    font-size: medium;
    
}
.questionText{
    font-size: small;
    
}

#radioChoice{
    --ion-background-color:#fff;
    
}
.answerList{
    background-color: white;
}