#modalButtonIcon{
    margin-right: 20px;
    
}
#forecastModalDate{
    width: min-content;
    font-size: large;
    font-weight: 800;
    display: inline-flex;
}
.modalDistrictText{
    font-size: small;
    width: 100vw;
}
.modalAirQualitySite{
    font-size: small;
    color:gray
}
#modalLevelText{
    position: relative;
    left: 40vw;
}
.forecastModalRow{
    border-bottom-color:#ECECEC;
    border-bottom-width: 2px;
    border-bottom-style: solid;
}
.modalTempertureDetail{
    font-size: large;
    margin-right: 40px;
    text-align: center;
    
}
#modalWeatherIcon{
    font-size: 80px;
    margin-right: 25px;
}