
#date{
    color: #333333;
    padding-right: 20px;
    font-size: 15px;
    padding-left:0 ;
}
#weatherTitle{
    font-size: large;
    
}
#weatherArea{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 15px;
    color: #333333;
    text-transform: uppercase;
}
#weatherIcon{
    font-size: 20vw;
    color: black;
}
.temperture{
 
    font-size: xx-large;
    padding-right: 50px;

    color: black;
}
.dayAndNight{
    padding-right: 50px;
    color: gray;

}
#shortDescription{
    margin-top: 50px;
    color: black;

}
#weatherDetailCard{
    width: 100vw;
    background-color: #F4F4F4;
    margin-left: 0;
}
#weatherCardTitle{
    font-weight: bold;
    font-size: large;
    padding-left: 5vw;
    padding-right: 5vw;

}
#weatherCardContent{

    padding-left: 5vw;
    padding-right: 5vw;
 
}
#weatherContent{
    box-shadow: none;
    padding-left: 4vw;
    padding-right: 4vw;
   
}


@media(min-width:700px){

    #date{
        font-size: x-large;
        margin: 10px;   
        margin-left: 0;
    }

    #weatherTitle{
        font-size: xx-large;
        
        
    }

    #weatherArea{
        font-size: x-large;

    }
    .temperture{
        font-size: xx-large;
        padding-left: 2vw;
    }
    .dayAndNight{
        font-size: x-large;
    }
    #shortDescription{
        font-size: x-large;
    }
    #weatherCardTitle{
        font-size: x-large;
    }
    #weatherCardContent{
        font-size: large;
    }
    #todayWeatherIcon{
        font-size: xx-large;
        padding-right: 1vw;

    }
    #todayWeatherToolbar{
        padding-top: 10px;
        padding-bottom: 20px;
    }

}