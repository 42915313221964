#signinAdviceCard {
    box-shadow: none;

}

#signinAdviceText {
    font-size: large;
    color: black;
}


.accountAva {
    height: 100px;
    width: 100px;
    border-radius: 75px;
    font-size: x-large;
    color: white;
}

#userIniAccount {
    margin: auto;
    font-size: 40px;
    line-height: 52px;
    color: white;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.userNameFiled {
    text-align: center;
    margin-top: 2vh;
    margin-bottom: 4vh;
    font-size: x-large;
    text-underline-position: below;
    height: 40px;
    border-bottom: 1.2px lightgray;
    border-bottom-style: solid;
    --placeholder-color: black;
    --placeholder-opacity: 1;
}

.accountSettingCard {
    font-size: large;
    box-shadow: none;

}

.settingLabel {
    font-size: large;
    color: black;
    line-height: 15px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-left: 15px;
}

.AccountSettingInput {
    font-size: large;
    margin-bottom: 15px;
    --placeholder-color: black;
    --placeholder-opacity: 0.85;
    --padding-start: 10px;
    border-bottom: 1.2px lightgray;
    border-bottom-style: solid;

}

#accountSettingButtonCard {
    text-align: right;
    box-shadow: none;
}

#addProfileCard {
    box-shadow: none;
    padding-left: 15px;
    padding-right: 15px;
    color: black;

}

#additionalProfileChips {
    height: 75px;
    width: 75px;

    border-radius: 75px;
    font-size: x-large;
    color: white;
    display: flex, inline-block;

}

.additionalProfileLabel {
    margin: auto;
    display: inline-block;
}

@media(min-width:700px) {
    .accountAva {
        height: 125px;
        width: 125px;

    }

    #userIniAccount {
        font-size: 50px;
    }

    #additionalProfileChips {
        height: 100px;
        width: 100px;
        border-radius: 100px;
    }

    .additionalProfileLabel {
        font-size: 35px;
    }

    #subText {
        font-size: 20px;
    }
}