#header_all{
   color:white;
   text-align: left;
    padding-left:10px;
    position: relative;  
}
#header_all_toobar{
    background-color: #094183 !important;
    margin-bottom: 2vh;
}

ion-menu-button{
    color:white
}
#headerIcon{
    vertical-align: middle;
    display: inline-block;
}

#headerText{
    margin-left: 10px;
    vertical-align: middle;
}

#initialBadage{
    width: 40px
}

@media(min-width:600px){
    #header_all{
        height:60px;
             
     }
     #header_all_toobar{
        height:60px;
        padding-bottom: 2vh;
    }
     #headerText{
        font-size: 25px;
    }
    #initialBadage{
        font-size: large;
        width: 10vw
    }
    #headerMenuButton{
        font-size: 40px;
    }
}