#forecast_row{
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap;
    margin-left: 5%;
    
}
.forecastChart{
    top: 0px;
    height: 6vh;
    margin-bottom: 20px;
    
}
.forecastCard{
    
    width: 30vw;
    height: auto;
    box-shadow:none;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
 
}
.forecastCol{
    
    border-right: solid;
    border-right-color: gainsboro;
    padding-top: 0;
}
.forecastCardContent{
    margin: 0;
    padding: 0;
  
}
.forecastSub{
    margin-bottom: 0;
    padding-bottom: 0;
}
.forecastInformation{
    position: relative;
    top: -20px;
    height: 40px;
}
.forecastRow{
    text-align: top;
}
.forecastDate{
    padding-left: 1px;
    text-align: left;
    font-size: x-small;
    font-weight: bold;
    padding-bottom: 0;
    font-family: 'Times New Roman', Times, serif;
    text-transform: uppercase;
    
}
#level{
    font-size: 15px;
    font-weight: 700;
    position: relative;
    top: -20px;
}

/*modal*/
#forecastModal{
    --padding-start:25px ;
    --padding-end: 25px;
    --padding-top: 30px;
}
#forcastModalButton{
    width: min-content;
    background-color: transparent;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

@media(max-width:400px){
    .forecastCard{
        
        width: 45vw;
     
    }
}
@media(min-width:700px){
    .forecastCard{
        
        width: 17vw;
        font-size: x-large;
    }
    .forecastCol{
        flex: 0 0 4px;
        padding:0;
        
    }
    .forecastDate{
        padding-bottom: 5px;
        font-size: small;
    }
    #level{
        font-size: 20px;

    }
    .forecastCardContent{
        font-size: large;
    }
    .forecastChart{
        
        height: 6.5vh;
        
    }
    
}
@media (min-height:1000px){
    .forecastChart{
        
        height: 4vh;
        
    }
}
