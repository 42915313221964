.helpInfoContent:first-letter {
    float: left;
    font-size: 45px;
    line-height: 0.75;
    font-weight: bold;
    margin-right: 9px;
}

.helpInfoContent {
    color: black;
    white-space: pre-wrap;
    margin-left: 15px;
    margin-right: 15px;
}

.helpImg {

    margin-bottom: 25px;
    width: 300px;
    height: auto;

}

@media(max-width:400px) {
    .helpInfoContent {

        margin-left: 2px;
        margin-right: 2px;
    }
}

@media(min-width:700px) {
    .helpInfoContent {
        font-size: large;
    }
    .helpImg {

        width: 45%;
        height: auto;
    
    }
}
@media(min-width:1000px) {
    .helpInfoContent {
        font-size: x-large;
    }

}