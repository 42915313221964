.categorSlide{
    display: block;
    height: 100%;
    
}

#surveySlides{
    height: auto;
   
}
#surveyCard{
    margin-top: 0;
    padding-top: 0;
    margin-left: 0;
    background-color:#f9f9f9;
    
}

.questionCard{
    position: relative;
    margin-top: 10px;
    background-color: white;
}

.swiper-pagination{
    position: relative;
    top: 20px;
    height:10px!important;
}
#surveyNameTitle{
    font-size: x-large;
    font-family: sans-serif;
    color: #515d6d;
}

#surveyDescriptionSubtitle{
    font-family: sans-serif;
    font-size: 13px;
}
#surveyNameHeader{

    text-align: center;
    padding-bottom: 30px;
}

#surveyPageContent{
    --ion-background-color:white;
   
}

#surveySlidesContent{
    --ion-background-color: #f9f9f9;
  
}
.questionName{
    font-size: larger;
    font-weight: 300;
    font-family: sans-serif;

 }
 .ModalBackButton{
    background-color: transparent!important;
    font-size:large;

    
    
}
 #surveyTitle{
     font-size: medium;
     color: black;
     text-align: center;
     position: relative;
     left: -3%;
 }

#chartPopover{
    --max-height:100%;
    --max-width:450px;
    --min-width:97%
}
#chartPopover .c3-axis-y text{
    transform: rotate(90deg) translateX(20px) translateY(-10px)   ;
    text-anchor:start;
   
}
#chartPopover .c3-axis-x text{
    transform: rotate(90deg) translateX(20px)  translateY(20px) 
} 

#chartPopover .c3-tooltip-container{
    transform: rotate(90deg);
}
#chartPopover .c3-legend-item{
    transform: rotate(90deg) translateY(-300px);
}
