.newsCardTitle{
    font-size: medium;
    font-weight:bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


#news_row{
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap;
    
}
.newsCard{
    width:auto;
    box-shadow:none;
    margin-top: 0;
    margin-bottom: 0;
  
}
.newsCol{
    border-right-width: 3px;
    border-right: solid;
    border-right-color: gainsboro;
    
    padding-top: 0;
}
.newsDate{
    padding: 0;
    font-family: 'Times New Roman', Times, serif;
    text-transform: uppercase;
}

.newsHeader{
    padding-bottom: 1vh;
    padding-top: 0;
}

@media(max-width:400px){
    .newsCard{
        width:65vw;
 
      
    }
}


@media(min-height:650px){
    .newsCard{
        height: auto;
        width: 64vw;
    }


}
@media(min-width:700px){
    .newsCardTitle{
        font-size: x-large;
    }

    .newsCard{
        width: auto;

    }

    .newsSub{
        font-size: x-large;
    }
}

@media(min-height:800px){
    .newsCard{
        height: auto;
    }
    
}


